import React from "react"
import { Container, Overview, Share } from "./EventNews.styles"

import { Link } from "gatsby"

//share icons
import Faceboook from "../../assets/icons/facebook-share.svg"
import Twitter from "../../assets/icons/twitter-share.svg"
import Linkedin from "../../assets/icons/linkedin-share.svg"
import Whatsapp from "../../assets/icons/whatsapp-icon.svg"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share"

const EventNews = ({ data, authorData,arabic }) => {
  const currentUrl = window.location.href
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, "0")
  var mm = String(today.getMonth() + 1).padStart(2, "0")
  var yyyy = today.getFullYear()
  today = yyyy + "-" + mm + "-" + dd
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Overview>
              <h4>{data?.name}</h4>
              <p>{data?.topic}</p>
            </Overview>
            <Overview>
              {/* <h4 className="bio">{authorData.first_name}'s Bio</h4> */}
              {/* <p>{authorData.sellerBio}</p> */}
            </Overview>
          </div>
          <div className="col-lg-4">
            {data?.date > today && (
              <Link to="/" className="attend">
                {arabic==false?'I WOULD LIKE TO ATTEND THIS EVENT':'أود حضور هذا الحدث'}
              </Link>
            )}

            <Share>
              <div className="text">{arabic==false?'Share on':'مشاركه فى'}</div>
              <div className="socials">
                <FacebookShareButton url={currentUrl} title="Facebook">
                  <img src={Faceboook} alt="facebook icon" />
                </FacebookShareButton>
                <TwitterShareButton url={currentUrl} title="Twitter">
                  <img src={Twitter} alt="twitter icon" />
                </TwitterShareButton>
                <LinkedinShareButton url={currentUrl} title="Linkedin">
                  <img src={Linkedin} alt="linkedin icon" />
                </LinkedinShareButton>
                <WhatsappShareButton url={currentUrl} title="Whatsapp">
                  <img src={Whatsapp} alt="whatsapp icon" />
                </WhatsappShareButton>
              </div>
            </Share>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default EventNews
